import Vue from "vue";
import Router from "vue-router";

const HomePage = () => import("./pages/HomePage");
const LoginPage = () => import("./pages/LoginPage");
const NotificationsPage = () => import("./pages/NotificationsPage");
const DistributersPage = () => import("./pages/DistributersPage");
const DistributerDetails = () => import("./pages/DistributerDetails");
const DistributerProfile = () => import("./pages/DistributerProfile");
const TransactionsPage = () => import("./pages/TransactionsPage");
const LegacyTransactionDetailsPage = () =>
  import("./pages/LegacyTransactionDetailsPage");
const ProductsPage = () => import("./pages/ProductScreens/ProductsPage");

const AssignProductPage = () =>
  import("./pages/ProductScreens/AssignProductPage");
const AddProductPage = () => import("./pages/ProductScreens/AddProductPage");
const DraftProduct = () => import("./pages/ProductScreens/DraftProduct");
const ProductDetails = () => import("./pages/ProductScreens/ProductDetails");

const MarketingSection = () =>
  import("./pages/MarketingScreens/MarketingSection");
const assginFeauturedProduct = () =>
  import("./pages/MarketingScreens/assginFeauturedProduct");
const createAdPage = () => import("./pages/MarketingScreens/createAdPage");
const CreateAdsPage = () =>
  import("./pages/MarketingScreens/Ads/CreateAdsPage.vue");

const editAdPage = () => import("./pages/MarketingScreens/Ads/editAdPage.vue");
const AdsPage = () => import("./pages/MarketingScreens/Ads");
const RatingsPage = () => import("./pages/MarketingScreens/Ratings");

const TopDeals = () => import("./pages/MarketingScreens/TopDeals");

const categoiesPage = () => import("./pages/categoiresScreens/categoiresPage");

const categoryPage = () =>
  import("./pages/categoiresScreens/categoryDetailsPage");

const userGroupsPage = () =>
  import("./pages/UserGroupScreens/userGroupsPage.vue");

const addUserGroupPage = () =>
  import("./pages/UserGroupScreens/addUserGroupPage.vue");

const editUserGroupPage = () =>
  import("./pages/UserGroupScreens/editUserGroupPage.vue");

const testUserGroup = () =>
  import("./pages/UserGroupScreens/testUserGroup.vue");

const RegionsPages = () => import("@/pages/RegionsScreens/RegionsPages.vue");

const PolygonsAreas = () => import("@/pages/PolygonsAreasScreens");

const SectionsPage = () => import("@/pages/SectionsScreens/SectionsPage.vue");

const PromocodesPage = () =>
  import("@/pages/MarketingScreens/Promocodes/PromocodesPage.vue");

const ForceResetPassword = () =>
  import("@/pages/EntitiesScreens/ForceResetPassword/index.vue");

const ProviderManagementPage = () =>
  import("@/pages/EntitiesScreens/ProviderManagement");
// const CreditWalletsPage = () =>
//   import("@/pages/CreditScreens/Wallets/index.vue");

// const CreditCollectorssPage = () =>
//   import("@/pages/CreditScreens/Collectors/index.vue");

// const CollectorDetailsPage = () =>
//   import("@/pages/CreditScreens/Collectors/CollectorDetails.page");

// const CreditFinancePage = () =>
//   import("@/pages/CreditScreens/Finance/index.vue");

// const RetailerWalletDetailsPage = () =>
//   import(
//     "@/pages/CreditScreens/Wallets/RetailersWallets/RetailerWalletDetailsPage.vue"
//   );

const RetailersPage = () =>
  import("./pages/EntitiesScreens/RetailersManagement");

const RetailersDetailsPage = () =>
  import(
    "./pages/EntitiesScreens/RetailersManagement/RetailersDetailsPage.vue"
  );

const NewTransactionDetailsPage = () =>
  import(
    "./pages/TransactionScreens/TransactionsManagement/TransactionDetails/index.vue"
  );

const AuditPage = () => import("@/pages/TransactionScreens/Audit");

const SalesAgentsPage = () =>
  import("./pages/EntitiesScreens/FaturaTeam/SalesAgents/index.vue");

const OtpHandlingPage = () =>
  import("./pages/EntitiesScreens/OTPHandling/index.vue");

const UnitsPage = () => import("@/pages/CatalogScreens/Units/index.vue");
const OffersPage = () => import("@/pages/MarketingScreens/Offers/index.vue");
// const CreditBundlesPage = () =>
//   import("@/pages/CreditScreens/Bundles/index.vue");

// const CreditBundleDetails = () =>
//   import("@/pages/CreditScreens/Bundles/bundleDetails.vue");
// const CreditBundleEntitiesIds = () =>
//   import("@/pages/CreditScreens/Bundles/bundleEntitiesIds.vue");
// const LeadsPage = () => import("@/pages/CreditScreens/Leads/index.vue");
// const LegalsPage = () => import("@/pages/CreditScreens/Legals/index.vue");
const TargetsPage = () => import("@/pages/MarketingScreens/Targets/index.vue");
const WholesalersAvailabilityPage = () =>
  import("./pages/ProviderScreens/WholesalersAvailability");
const CashbackRedemption = () => import("./pages/Cashback/redemption");
const CashbackManagement = () => import("./pages/Cashback/management");
const ProductsCashback = () => import("./pages/Cashback/product");
const SingleCashback = () =>
  import("./pages/Cashback/management/singleCashback.vue");
const SingleProductCashback = () =>
  import("./pages/Cashback/product/singleProductCashback.vue");
Vue.use(Router);

export const router = new Router({
  routes: [
    {
      path: "/login",
      component: LoginPage,
      meta: {
        layout: "no-sidebar",
      },
    },
    {
      path: "/home",
      name: "home",
      component: HomePage,
    },
    {
      path: "/marketing/notifications",
      name: "notifications",
      component: NotificationsPage,
    },
    {
      path: "/providers-management/distributers",
      name: "distributersList",
      component: DistributersPage,
    },
    {
      path: "/providers-management/distributers/:id",
      name: "distributerDetails",
      component: DistributerDetails,
    },
    {
      path: "/providers-management/distributers/:id/profile",
      name: "distributerProfile",
      component: DistributerProfile,
    },
    {
      path: "/transactions",
      name: "transactionsList",
      component: TransactionsPage,
    },
    {
      path: "/oldtransactions/:id",
      name: "legacyTransactionsDetails",
      component: LegacyTransactionDetailsPage,
    },
    {
      path: "/transactions/:id",
      name: "newTransactionsDetails",
      component: NewTransactionDetailsPage,
    },
    {
      path: "/products",
      name: "productsList",
      component: ProductsPage,
    },
    {
      path: "/products/add",
      name: "addProduct",
      component: AddProductPage,
    },
    {
      path: "/products/drafts",
      name: "draftProduct",
      component: DraftProduct,
    },

    {
      path: "/products/add/:id",
      name: "AssignProduct",
      component: AssignProductPage,
    },
    {
      path: "/products/:id",
      name: "productDetails",
      component: ProductDetails,
      props: true,
    },
    {
      path: "/marketing",
      name: "marketingsection",
      component: MarketingSection,
    },
    {
      path: "/marketing/assginFeauturedProduct",
      name: "assginFeauturedProduct",
      component: assginFeauturedProduct,
    },
    {
      path: "/marketing/createAdPage",
      name: "createAdPage",
      component: createAdPage,
    },
    {
      path: "/marketing/ad/add",
      name: "CreateAdsPage",
      component: CreateAdsPage,
    },

    {
      path: "/marketing/ad/edit/:id",
      name: "editAdPage",
      component: editAdPage,
    },

    {
      path: "/marketing/topDeals",
      name: "TopDeals",
      component: TopDeals,
    },
    {
      path: "/marketing/AdsPage",
      name: "AdsPage",
      component: AdsPage,
    },
    {
      path: "/marketing/ratings",
      name: "RatingsPage",
      component: RatingsPage,
    },
    {
      path: "/marketing/promocodes",
      name: "PromocodesPage",
      component: PromocodesPage,
    },
    {
      path: "/categories",
      name: "categoriesList",
      component: categoiesPage,
    },
    {
      path: "/categories/:id",
      name: "category",
      component: categoryPage,
    },
    {
      path: "/userGroups",
      name: "userGroups",
      component: userGroupsPage,
    },

    {
      path: "/userGroups/add",
      name: "addUserGroup",
      component: addUserGroupPage,
    },

    {
      path: "/userGroups/details/:id",
      name: "editUserGroup",
      component: editUserGroupPage,
    },
    {
      path: "/userGroups/test",
      name: "testUserGroup",
      component: testUserGroup,
    },
    {
      path: "/regions",
      name: "regions",
      component: RegionsPages,
    },
    {
      path: "/polygons-areas",
      name: "PolygonsAreas",
      component: PolygonsAreas,
    },
    {
      path: "/sections",
      name: "sections",
      component: SectionsPage,
    },
    //
    {
      path: "/forceResetPassword",
      name: "forceResetPassword",
      component: ForceResetPassword,
    },
    {
      path: "/entities/provider-area",
      name: "ProviderManagementPage",
      component: ProviderManagementPage,
    },
    {
      path: "/audit",
      name: "audit",
      component: AuditPage,
    },
    // {
    //   path: "/credit/wallets",
    //   name: "wallets",
    //   component: CreditWalletsPage,
    // },
    // {
    //   path: "/credit/collectors",
    //   name: "collectors",
    //   component: CreditCollectorssPage,
    // },
    // {
    //   path: "/credit/collectors/:id",
    //   name: "collectorDetails",
    //   component: CollectorDetailsPage,
    // },

    // {
    //   path: "/credit/finance",
    //   name: "finance",
    //   component: CreditFinancePage,
    // },
    // {
    //   path: "/credit/wallets/retailerWallet/:id",
    //   name: "retailerWallet",
    //   props: true,
    //   component: RetailerWalletDetailsPage,
    // },
    {
      path: "/entities/retailers",
      name: "RetailersPage",
      component: RetailersPage,
    },
    {
      path: "/entities/retailers/:id",
      name: "RetailersDetailsPage",
      props: true,
      component: RetailersDetailsPage,
    },
    {
      path: "/entities/faturaTeam/salesAgents",
      name: "SalesAgentsPage",
      component: SalesAgentsPage,
    },
    // {
    //   path: "/credit/bundles",
    //   name: "bundles",
    //   component: CreditBundlesPage,
    // },
    // {
    //   path: "/bundleDetails/:id",
    //   name: "bundleDetails",
    //   component: CreditBundleDetails,
    // },
    // {
    //   path: "/bundleEntitiesIds/:id",
    //   name: "bundleEntitiesIds",
    //   component: CreditBundleEntitiesIds,
    // },
    // {
    //   path: "/entities/faturaTeam/portalUsers",
    //   name: "PortalUsersPage",
    //   component: PortalUsersPage,
    // },
    {
      path: "/entities/faturaTeam/otp",
      name: "otpHandlingPage",
      component: OtpHandlingPage,
    },
    {
      path: "/catalog/units",
      name: "units",
      component: UnitsPage,
    },
    {
      path: "/marketing/offers",
      name: "OffersPage",
      component: OffersPage,
    },
    // {
    //   path: "/credit/leads",
    //   name: "creditLeadsPage",
    //   component: LeadsPage,
    // },
    // {
    //   path: "/credit/legals",
    //   name: "creditLegalPage",
    //   component: LegalsPage,
    // },
    {
      path: "/marketing/targets",
      name: "TargetsPage",
      component: TargetsPage,
    },

    // {
    //   path: "/credit/limit",
    //   name: "LimitPage",
    //   component: LimitPage,
    // },

    {
      path: "/cashback/redemption",
      name: "CashbackRedemption",
      component: CashbackRedemption,
    },
    {
      path: "/cashback/management",
      name: "CashbackManagement",
      component: CashbackManagement,
    },
    {
      path: "/cashback/management/:action?/:id?/:type?",
      name: "SingleCashback",
      component: SingleCashback,
    },
    {
      path: "/cashback/products",
      name: "ProductsCashback",
      component: ProductsCashback,
    },
    {
      path: "/cashback/products/:action?/:id?/:type?",
      name: "SingleProductCashback",
      component: SingleProductCashback,
    },
    {
      path: "/providers-management/wholesalers-availability",
      name: "wholesalersAvailability",
      component: WholesalersAvailabilityPage,
    },

    // otherwise redirect to home
    { path: "*", redirect: "/transactions" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("userToken");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
