function getNavItems() {
  // let showCreditOptions = false;

  return [
    {
      index: "/transactions",
      label: "sideBarHeaderLabelTransactions",
      route: { name: "transactionsList" },
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/transactions",
          label: "sideBarHeaderLabelTrxMngmt",
          route: { name: "transactionsList" },
        },
        {
          index: "/audit",
          label: "sideBarHeaderLabelAudit",
          route: { name: "audit" },
        },
      ],
    },
    {
      index: "/catalog",
      label: "sideBarHeaderLabelCatalog",
      route: { name: "transactionsList" },
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/products",
          label: "sideBarHeaderLabelProducts",
          route: { name: "productsList" },
        },
        {
          index: "/categories",
          label: "sideBarHeaderLabelCategories",
          route: { name: "categoriesList" },
        },
        {
          index: "/units",
          label: "sideBarHeaderLabelUnits",
          route: { name: "units" },
        },
      ],
    },
    {
      index: "/providers-management",
      label: "Providers Management",
      route: { name: "providersManagement" },
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/distributers",
          label: "sideBarHeaderLabelProviders",
          route: { name: "distributersList" },
        },
        {
          index: "/wholesalers-availability",
          label: "Wholesalers Availability Requests",
          route: { name: "wholesalersAvailability" },
        },
      ],
    },
    {
      index: "/regions",
      label: "sideBarHeaderLabelRegionsAreas",
      route: { name: "regions" },
      compName: "el-menu-item",
    },
    {
      index: "/polygons-areas",
      label: "Polygons/ Areas",
      route: { name: "PolygonsAreas" },
      compName: "el-menu-item",
    },
    {
      index: "/sections",
      label: "sideBarHeaderLabelSections",
      route: { name: "sections" },
      compName: "el-menu-item",
    },
    {
      index: "/marketing",
      label: "sideBarHeaderLabelMarketing",
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/marketing/AdsPage",
          label: "sideBarHeaderLabelAds",
          route: { name: "AdsPage" },
        },
        {
          index: "/marketing/notifications",
          label: "sideBarHeaderLabelNotifications",
          route: { name: "notifications" },
        },
        {
          index: "/marketing/promocodes",
          label: "sideBarHeaderLabelPromocodes",
          route: { name: "PromocodesPage" },
        },
        {
          index: "/marketing/topDeals",
          label: "sideBarHeaderLabelTopDeals",
          route: { name: "TopDeals" },
        },
        {
          index: "/marketing/offers",
          label: "Product Offers Handling",
          route: { name: "OffersPage" },
        },
        {
          index: "/marketing/targets",
          label: "Targets Handling",
          route: { name: "TargetsPage" },
        },
        {
          index: "/marketing/ratings",
          label: "Ratings",
          route: { name: "RatingsPage" },
        },
      ],
    },
    {
      index: "/cashback",
      label: "Cashback",
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/cashback/redemption",
          label: "Cashback Redemption",
          route: { name: "CashbackRedemption" },
        },
        {
          index: "/cashback/management",
          label: "Cashback Management",
          route: { name: "CashbackManagement" },
        },
        {
          index: "/cashback/products",
          label: "Products Cashback",
          route: { name: "ProductsCashback" },
        },
      ],
    },
    {
      index: "/entities",
      label: "sideBarHeaderLabelEntites",
      compName: "el-submenu",
      nestedRoutes: [
        {
          index: "/entities/provider-area",
          label: "sideBarHeaderLabelProviderArea",
          route: { name: "ProviderManagementPage" },
        },
        {
          index: "/entities/retailers",
          label: "sideBarHeaderLabelRetailers",
          route: { name: "RetailersPage" },
        },
        {
          index: "/entities/faturaTeam/salesAgents",
          label: "sideBarHeaderLabelSalesAgents",
          route: { name: "SalesAgentsPage" },
        },
        // {
        //   index: "/entities/faturaTeam/portalUsers",
        //   label: "sideBarHeaderLabelPortalUsers",
        //   route: { name: "PortalUsersPage" },
        // },
        {
          index: "/entities/faturaTeam/otp",
          label: "sideBarHeaderLabelOtpHandling",
          route: { name: "otpHandlingPage" },
        },
      ],
    },
    // ...(showCreditOptions
    //   ? [
    //       {
    //         index: "/credit",
    //         label: "sideBarHeaderLabelCreditOps",
    //         compName: "el-submenu",
    //         nestedRoutes: [
    //           {
    //             index: "/credit/wallets",
    //             label: "sideBarHeaderLabelWallets",
    //             route: { name: "wallets" },
    //           },
    //           {
    //             index: "/credit/collectors",
    //             label: "sideBarHeaderLabelCollectors",
    //             route: { name: "collectors" },
    //           },
    //           {
    //             index: "/credit/finance",
    //             label: "sideBarHeaderLabelFinance",
    //             route: { name: "finance" },
    //           },
    //           {
    //             index: "/credit/leads",
    //             label: "Leads",
    //             route: { name: "creditLeadsPage" },
    //           },
    //           {
    //             index: "/credit/limit",
    //             label: "Limit",
    //             route: { name: "LimitPage" },
    //           },
    //         ],
    //       },
    //     ]
    //   : []),
  ];
}

export const NavItemsConfiguration = {
  getNavItems,
};
